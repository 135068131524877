import axios from "axios";
// import router from "../router/index";
// import store from "../store/store";
import Toasted from "vue-toasted";

// Register the Toasted plugin globally
Vue.use(Toasted);
import Vue from "vue";
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.request.use(function (config) {
  const user_access_token = localStorage.getItem("user_access_token");

  if (user_access_token) {
    config.headers.Authorization = `Bearer ${user_access_token}`;
  }
  return config;
});

axios.interceptors.response.use(
  async function (response) {
    return response;
  },

  async function (error) {
    // const originalRequest = error.config;
    if (error.response.status === 401) {
      if (localStorage.getItem("user_access_token"))
        localStorage.removeItem("user_access_token");
      let URL = 'https://quizell.com/';
      
      window.open(URL, "_self");
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

