<template>
    <div class="" style="height: 98vh">
        <div class="colon-broom-progress-bar w-100">
        <div class="active-bar" :style="`width: ${calcWidth}%`"></div>
        </div>
        <div class="colon-broom-info">
            <div class="colon-broom-image-dev"><LazyImage class="colon-broom-image" src="https://images.quizell.com/website/colonbroomlogo.svg"></LazyImage></div>
        </div>
<LazyImage class="d-none d-md-block"  src="https://images.quizell.com/colon-broom/steps-dots.svg" style="   position: fixed;
    width: 63px;
    height: 102px;
    left: 0px;
    top: 36%;" ></LazyImage>
        <div class="">
         
<LazyImage class="step-black" src="https://images.quizell.com/colon-broom/step-black.svg" style="position: fixed; bottom: 0px; right: 0px;" ></LazyImage>
</div>
<div class=""><h4 class="question-heading">{{ getHeading[0].content }}
</h4></div>
<div class="d-flex justify-content-center align-items-center">
        <div class="px-3" style="width: 447px;">
        <div class="d-flex align-items-center justify-content-center menu mb-3">
        <div
        @click="changeActiveTab('imperial')"
          :class="activeTab=='imperial'?'active':''"
        >
Imperial
    </div>
        <div
         @click="changeActiveTab('metric')"
         :class="activeTab!='imperial'?'active':''"
        >
         Metric
        </div>
      </div>
      <form @submit.prevent="calculateBMI()">
      <div class="d-flex flex-column mt-4" style="gap: 40px;">
      <div class="">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.age" required :min="minMaxValidation[activeTab].age.min" :max="minMaxValidation[activeTab].age.max" type="number" placeholder="Age">
            years
        </div>
      </div>
      <div class="row" v-if="activeTab=='imperial'">
        <div class="col-6">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.height" :min="minMaxValidation[activeTab].height.min" :max="minMaxValidation[activeTab].height.max" required type="number" placeholder="Height">
            ft
        </div>
      </div>
      <div class="col-6">
        <div class="colon-broom-input-control d-flex gap">
            <input required v-model.number="userInformation.in" :min="minMaxValidation[activeTab].in.min" :max="minMaxValidation[activeTab].in.max" type="number" placeholder="in">
            in
        </div>
      </div>
      </div>
      <div class="" v-else>
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.height" :min="minMaxValidation[activeTab].height.min" :max="minMaxValidation[activeTab].height.max" required type="number" placeholder="Height">
            cm
        </div>
      </div>
      <div class="">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.weight" :min="minMaxValidation[activeTab].weight.min" :max="minMaxValidation[activeTab].weight.max" required type="number" placeholder="Weight">
           <span> {{ activeTab=='imperial'?'lb':'kg' }}</span>
        </div>
      </div>
      <div class="">
        <div class="colon-broom-input-control d-flex gap">
            <input v-model.number="userInformation.desiredWeight" required :min="minMaxValidation[activeTab].desiredWeight.min" :max="minMaxValidation[activeTab].desiredWeight.max" type="text" placeholder="Desired weight">
            {{ activeTab=='imperial'?'lb':'kg' }}
        </div>
    </div>
    <div class="">
        {{ bmi?.toFixed(2) }}
        <div v-if="bmi!=null&&bmi < 18.5">
                <p style="color: red;">You are underweight. Please consult a healthcare provider.</p>
            </div>
            <div v-if="bmi >= 18.5 && bmi < 24.9">
                <p style="color: green;">You are in the normal weight range. Keep up the good work!</p>
            </div>
            <div v-if="bmi >= 25 && bmi < 29.9">
                <p style="color: orange;">You are overweight. You might consider our supplements to support your health.</p>
            </div>
            <div v-if="bmi >= 30">
                <p style="color: red;">You are obese. Please consult a healthcare provider for advice.</p>
            </div>
            <div v-if="userInformation.age > 65 && bmi >= 25">
                <p style="color: blue;">As you are over 65, it is important to manage your weight carefully. Consult your healthcare provider for personalized advice.</p>
            </div>
    </div>
    <div class="d-flex flex-column justify-content-center align-items-center">

        <button class="colon-broom-next-button " type="submit">Continue</button>
<div class=""  style="cursor:pointer; padding-top: 32px;">
  <div class="d-flex gap align-items-center" @click="backPage">
    <div class="colon-broom-svg">
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_211_2723)">
<path d="M5.50618 7.21666C7.75027 7.21666 9.99437 7.21666 12.2385 7.21666C13.1534 7.21666 14.0683 7.29029 14.9796 7.38065C15.646 7.44758 16.5177 7.44089 16.4997 8.32105C16.4817 9.20122 15.5884 9.16106 14.9364 9.22465C12.9481 9.4154 10.9525 9.40202 8.95697 9.40536H6.47514C6.50395 9.90736 6.90378 10.0011 7.17034 10.1651C8.19705 10.8167 9.27329 11.3983 10.3906 11.9053C11.0354 12.1864 11.5144 12.6449 11.1939 13.351C10.8733 14.0572 10.1493 14.1208 9.44685 13.8564C8.80905 13.6195 8.1934 13.3341 7.60619 13.003C5.90241 12.0425 4.26346 10.9883 2.39038 10.309C2.15308 10.2096 1.92838 10.0862 1.72039 9.94083C0.113862 8.9201 0.0922496 8.07675 1.66276 6.99913C3.87083 5.48311 6.02127 3.88676 8.46709 2.69536C8.93519 2.46933 9.41618 2.26711 9.90792 2.08962C10.3366 1.93233 10.776 1.95575 11.0246 2.37408C11.2731 2.79241 11.0534 3.15385 10.6644 3.39815C9.75303 3.99051 8.82009 4.55274 7.90516 5.1384C7.01361 5.64373 6.18908 6.24467 5.44854 6.92885L5.50618 7.21666Z" fill="black"/>
</g>
<defs>
<clipPath id="clip0_211_2723">
<rect width="16" height="16" fill="white" transform="translate(0.5)"/>
</clipPath>
</defs>
</svg>

    </div>
    <div class="back-button-text">
      Go back
    </div>
  </div>
</div>
</div>

      </div>
    </form>
    </div>
</div>
</div>
</template>
<script>
import { mapGetters,mapActions,mapMutations } from 'vuex';

export default {
    data(){
       return {
           activeTab:'imperial',
           minMaxValidation:{
            imperial:{
                age:{
                    min:18,
                    max:120,
                },
                height:{
                    min:4,
                    max:18,
                },
                in:{
                    min:0,
                    max:11,
                },
                weight:{
                    min:88,
                    max:700,
                },
                desiredWeight:{
                    min:88,
                    max:700,
                },
            },
            metric:{
                age:{
                    min:18,
                    max:120,
                },
                height:{
                    min:140,
                    max:250,
                },
               
                weight:{
                    min:40,
                    max:300,
                },
                desiredWeight:{
                    min:40,
                    max:300,
                },
            },
           },
           userInformation:{
            age:'',
            weight:'',
            height:'',
            desiredWeight:'',
            in:'',
           },
           bmi:null,
    }
    
    },
    computed:{
        ...mapGetters([
      "getIsLoadingQuestion",
      "getSelectedPage",
      "getUploadedImage",
      "getTotalPages",
      "getTotalPreviewPages",
      "getPreviewSettings",
      "getPreviewProgressBar",
      "getSavedLeadID",
      "translateText",
      "getAnimationSetting",
      "getFurnitureClientCounterData",
      "getTrackIkeaClientCounter",
      "getEnvConst",
     "getSelectedOptions"
    ]),
    calcWidth() {
      const arr = this.getTotalPreviewPages;
      let totalPagesLength = this.getTotalPreviewPages.length
      let progressWidth=null;
       
      if (arr.length) {
        for (let i = 0; i < arr.length; i++) {
          
          if (
            this.getSelectedPage.value == arr[i].value &&
            this.getSelectedPage.order == arr[i].order
          ) {
            progressWidth = ((i + 1) / totalPagesLength) * 100;
          
          }
        }
      }
      
      return progressWidth;
    },
        getHeading() {
      return this.getSelectedPage.pageDesign.blocksArray.filter(
        (block) => block.type == "text"
      );
    },
    },
    methods:{
        ...mapActions([
        "goToNextPreviewPage" , "goToBackPreviewPage"]),
        ...mapMutations(["setSavedLeadID", "backPageAnimation",]),
        backPage() {
      this.backPageAnimation();
      this.goToBackPreviewPage();
    },
       async calculateBMI(){
            this.activeTab=='imperial'?this.calculateBMIImperial():this.calculateBMIMetric();
            let leadData = this.getSavedLeadID;
            leadData.custom_inputs =  {
                ...this.userInformation,
                bmi_type:this.activeTab,
                bmi:this.bmi.toFixed(2)
            };
           await this.setSavedLeadID(leadData);
            this.goToNextPreviewPage(this.getSelectedPage.value);
            
        },
        changeActiveTab(tab){
            this.activeTab=tab;
           this.userInformation={
            age:'',
            weight:'',
            height:'',
            desiredWeight:'',
            in:'',
           },
           this.bmi=null
        },

        calculateBMIMetric() {
                    if (this.userInformation.weight > 0 && this.userInformation.height > 0) {
                        const heightInMeters = this.userInformation.height / 100;
                       
                        this.bmi = this.userInformation.weight / (heightInMeters * heightInMeters);
                        
                    } else {
                        this.bmi = null;
                        alert('Please enter valid weight and height.');
                    }
                },
                calculateBMIImperial() {
                    if (this.userInformation.weight > 0 && this.userInformation.height > 0&& this.userInformation.in  >= 0 ) {
                        // Convert height to meters
                        const totalHeightInInches = (this.userInformation.height * 12) + this.userInformation.in;
                        const heightInMeters = totalHeightInInches * 0.0254;
                        
                        // Convert weight to kilograms
                        const weightInKg = this.userInformation.weight * 0.453592;
                        
                        this.bmi = weightInKg / (heightInMeters * heightInMeters);
                    } else {
                        this.bmi = null;
                        alert('Please enter valid weight, height, and age.');
                    }
                }
    }
}
</script>
<style scoped>
.menu {
  gap: 20px;
  border-bottom: 1px solid var(--border-color);
  padding-bottom: 15px;
}
.menu div {
  color: hsl(240, 10%, 50%);
  cursor: pointer;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;
  position: relative;
}
.menu div.active {
  color: rgb(34, 191, 142);;
}
.menu div.active:after {
  display: block;
  content: "";
  position: absolute;
  bottom: -15px;
  height: 2px;
  /* margin-bottom: 20px; */
  width: 100%;
  background: rgb(34, 191, 142);;
  border-radius: 4px 4px 0px 0px;
}
.colon-broom-input-control{
    border-bottom: 1px solid var(--border-color);
  padding-bottom: 10px;
    font-weight: 500;
    font-size: 14px;
    color: rgb(0, 0, 0);
    
}
.colon-broom-input-control:focus-within{
    border-bottom: 1px solid rgb(34, 191, 142);
}
.colon-broom-input-control input{
    border:none;
    outline: none;
    flex: 1;
}
.colon-broom-button{
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    letter-spacing: 0.04em;
    position: relative;
    display: block;
    padding: 18px 72px;
    margin: 0px auto;
    text-align: center;
    color: rgb(255, 255, 255);
    background: rgb(34, 191, 142);
    border-radius: 9999px;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
  
    border: none;
    text-transform: uppercase;
    box-shadow: inset 0 0 0 0 rgb(34, 191, 142);
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}

.colon-broom-button:hover {
  box-shadow: inset 0 100px 0 0 rgb(47 215 162);
}
.question-heading {
    display: block;
    z-index: 2;
    font-family: Recoleta, Recoleta1;
    font-size: 1.5rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-decoration: none;
}

.colon-broom-progress-bar{
height: 8px;
background-color: #E8F6F2;
}
.colon-broom-progress-bar .active-bar{
    position: relative;
    height: 8px;
    background-color: rgb(34, 191, 142);
    transition: width 0.2s;
}
.colon-broom-progress-bar .active-bar::after {
    position: absolute;
    width: 18px;
    height: 19px;
    content: "";
    background-image: url(https://images.quizell.com/colon-broom/progress-barDrum.svg);
    background-repeat: no-repeat;
    background-position: 50% center;
    background-size: contain;
    right: -18px;
}

.colon-broom-info {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 20px;
}

@media (min-width: 768px) {
    .colon-broom-info {
        padding: 34px 24px 56px;
    }
}

.colon-broom-info {
    max-width: 1200px;
    padding: 24px 24px 32px;
    margin: 0px auto;
  
}
@media (min-width: 640px) {
    .colon-broom-image {
        height: 19px!important;
    }
}

.colon-broom-image {
    height: 15px;
}

.colon-broom-image-dev {
    display: block;
    width: 100%;
    max-width: 1200px;
    margin: 0px auto;
    left: 0px;
    box-sizing: border-box;
    top: 40px;
}

.colon-broom-options {
    display: flex;
    -webkit-box-pack: center;
  
    -webkit-box-align: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    padding: 0px 0px 16px;
    gap: 24px;
}

.colon-broom-option.multi {
    width: 288px;
    height: 75px;
    padding: 0px;
    background-color: rgb(247, 249, 248);
}
@media (max-width: 639px) {
    .colon-broom-option {
        width: 100%!important;
        height: 75px!important;

    background-color: rgb(247, 249, 248)!important;
    padding: 20px!important;
    }
}

.colon-broom-option {
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    width: 208px;
    height: 238px;
    text-align: center;
    background: rgb(247, 249, 248);
    border: 2px solid transparent;
    border-radius: 4px;
    cursor: pointer;
    gap:20px;
}
/* .colon-broom-option div {
    position: absolute;
    top: 50%;
    left: 0px;
    right: 0px;
    padding: 0px 24px;
    transform: translateY(-50%);
} */
.colon-broom-text   {
    display: block;
    z-index: 2;
    font-size: 1.125rem;
    line-height: 1.5rem;
    font-weight: 500;
    letter-spacing: -0.01em;
    text-decoration: none;
    width: 100%;
}


@media (max-width: 639px) {
    .option-image {
        width: 40px!important;
        height: auto!important;
    }
}
.option-image {
    width: auto;
    height: 90px;
}
.colon-broom-selected-option{
  border-radius: 4px;
border: 2px solid var(--Green-Base, #22BF8E);
background: var(--Green-Lighter, #E8F6F2);
}
.cursor-none{
  pointer-events: none;
}


.back-button-text{
  color: var(--Grey-Black, #000);
    font-feature-settings: 'liga' off, 'clig' off;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

@media (max-width: 639px) {
    .step-black {
        width: 43px;
        height: 64px;
    }
    .colon-broom-next-button{
        width: 100%!important;
    }
    .colon-broom-tooltip{
        width: 100%!important;
    }
}
.colon-broom-next-button {
  padding: 18px 72px;
  border-radius: 40px;
  background: var(--Green-Base, #22BF8E);
  color: var(--Grey-White, #FFF);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
  letter-spacing: 1.2px;
  text-transform: uppercase;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.3s;
}
.colon-broom-next-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -200%;
  height: 200%;
  width: 100%;
  background: linear-gradient(120deg, rgba(255, 255, 255, 0.3) 25%, transparent 50%, rgba(255, 255, 255, 0.3) 75%);
  transition: left 0.5s;
  z-index: 1;
  transform: rotate(45deg);
}

.colon-broom-next-button:hover::before {
  width: 200%;
  left: 100%;
}

.colon-broom-next-button span {
  position: relative;
  z-index: 2;
 

}
</style>