


export default {
  updatePageCounter(state, pageCounter) {
    if(pageCounter){
      state.pageCounter = pageCounter;
    }
    
  },
  setIsLoadingQuestion(state,payload){
    state.isLoadingQuestion = payload;
  },
  resetLeadCounter(state) {
    state.currLeadPageCounter = {
      counter: 0,
      currLeadPageId: [],
    };
  },
  updateLeadCounter(state, leadPageId) {
    if (!state.currLeadPageCounter.currLeadPageId.includes(leadPageId)) {
      state.currLeadPageCounter.currLeadPageId.push(leadPageId);
      state.currLeadPageCounter.counter += 1;
    }
  },
  setTotalPages(state, pages) {
    state.totalPages = pages;
  },
  setTotalPageSingStep(state, page) {
    if (!state.previewTotalPages.includes(page))
      state.previewTotalPages.push(page);
  },
  setIsInvitedUser(state, payload) {
    state.isInvitedUser = payload;
  },
  setTotalPreviewPages(state, pages) {
    state.previewTotalPages = pages;
  },
  setSelectedPage(state, page) {
    state.selectedPage = page;
  },
  ResetStoredData(state) {
    state.selectedPage = {};
  },
  resetSavedLeadObj(state, obj) {
    state.savedLeadId = obj;
  },
  setProgressBar(state, obj) {
    state.progressBar = obj;
  },
  setPreviewProgressBar(state, obj) {
    state.previewProgressBar = obj;
  },
  setPreviewSettings(state, obj) {
    state.previewSettings = obj;
  },
  setEditorSettings(state, obj) {
    state.EditorSettings = obj;
  },
  updateSelectedCurrency(state, obj) {
    state.EditorSettings.selectedCurrency = obj;
  },
  updateShowLogo(state, value) {
    state.EditorSettings.showLogo = value;
  },
  updateSkipQuestion(state, value) {
    state.EditorSettings.skipQuestion = value;
  },
  updatehidePrice(state, value) {
    state.EditorSettings.hidePrice = value;
  },
  setCurrencies(state, currencies) {
    state.Currencies = currencies;
  },
  setImageUpload(state, img) {
    state.uploadedImage = img;
  },
  setIsAnyPageEdited(state, value) {
    state.isAnyPageEdited = value;
  },
  setSelectedTemplateDesign(state, value) {
    state.selectedTemplateDesign = value;
  },
  setUpdatePageValues(state, { pageDesignValue, id, value }) {
    for (let i = 0; i < state.totalPages.length; i++) {
      if (state.totalPages[i].id == id && state.totalPages[i].value == value) {
        state.totalPages[i].pageDesign = pageDesignValue;
      }
    }
  },
  setUpdatePageName(state, { pageName, id, value }) {
    for (let i = 0; i < state.totalPages.length; i++) {
      if (state.totalPages[i].id == id && state.totalPages[i].value == value) {
        state.totalPages[i].name = pageName;
      }
    }
  },

  setUpdatePageisMasterQuestion(state, { isMasterQuestion, order, value }) {
    for (let i = 0; i < state.totalPages.length; i++) {
      if (
        state.totalPages[i].order === order &&
        state.totalPages[i].value === value
      ) {
        state.totalPages[i].isMasterQuestion = isMasterQuestion;
      }
    }
  },
  setUpdatePageSkipStatus(state, { skip_status, order, value }) {
    for (let i = 0; i < state.totalPages.length; i++) {
      if (
        state.totalPages[i].order === order &&
        state.totalPages[i].value === value
      ) {
        state.totalPages[i].skip_status = skip_status;
      }
    }
  },
  setUpdatePageQuestionPageType(
    state,
    { name, order, questionPageType, icon }
  ) {
    for (let i = 0; i < state.totalPages.length; i++) {
      if (
        state.totalPages[i].order === order &&
        state.totalPages[i].name === name
      ) {
        state.totalPages[i].value = questionPageType;
        state.totalPages[i].icon = icon;

        const getSelectedOptionIndex = state.totalPages[
          i
        ].pageDesign.blocksArray.findIndex((x) => x.type == "option");
        if (questionPageType == "singleAnswer") {
          state.totalPages[i].pageDesign.blocksArray[
            getSelectedOptionIndex
          ].selectedOption = "";
        } else {
          state.totalPages[i].pageDesign.blocksArray[
            getSelectedOptionIndex
          ].selectedOption = [];
        }
      }
    }
  },
  setUpdatePreviewPageValues(state, { pageDesignValue, order, value }) {
    for (let i = 0; i < state.previewTotalPages.length; i++) {
      if (
        state.previewTotalPages[i].order === order &&
        state.previewTotalPages[i].value === value
      ) {
        state.previewTotalPages[i].pageDesign = pageDesignValue;
      }
    }
  },
  setSelectedOptions(state, obj) {
    const getObjQuesID = obj.questionId;
    const index = state.selectedOptions.questionAnswer.findIndex(
      (option) => option.questionId == getObjQuesID
    );

    if (index > -1) {
      state.selectedOptions.questionAnswer[index] = obj;
    } else {
      state.selectedOptions.questionAnswer.push(obj);
    }
  },
  setSelectedRangeSliders(state, obj) {
    const getSliderPageId = obj.id;
    const index = state.selectedRangeSliders.findIndex(
      (page) => page.id == getSliderPageId
    );

    if (index > -1) {
      state.selectedRangeSliders[index] = obj;
    } else {
      state.selectedRangeSliders.push(obj);
    }
  },
  resetSelectedOptions(state) {
    state.selectedOptions.questionAnswer = [];
  },
  resetRangeSliders(state) {
    state.selectedRangeSliders = [];
  },
  populateSetSelectedOptions(state, arr) {
    state.selectedOptions.questionAnswer = arr;
  },
  setUpdateResultPreviewProducts(
    state,
    { resultPageIndex, productArrayIndex, arr }
  ) {
    if (resultPageIndex && productArrayIndex && arr) {
      state.previewTotalPages[resultPageIndex].pageDesign.blocksArray[
        productArrayIndex
      ].content = arr;

      // state.selectedPage =  state.previewTotalPages[resultPageIndex]
    }
  },
  setupdateResultPreviewPageType(state, { resultPageIndex, pageType }) {
    if (resultPageIndex) {
      state.previewTotalPages[resultPageIndex].pageDesign.pageType = pageType;
      state.selectedPage = state.previewTotalPages[resultPageIndex];
    }
  },
  setCurrPageOrder(state, order) {
    state.currPageOrder = order;
  },

  jumpToPreviewPage(state, index) {
    state.selectedPage = state.previewTotalPages[index];
  },
  PreviewPagesStack(state, { type, currPageObj }) {
    if (type == "push") {
      state.previewPagesStack.push(currPageObj);
    } else if (type == "pop") {
      const lastPageInStack = state.previewPagesStack.pop();
      state.selectedPage = lastPageInStack;
    }
  },
  resetPreviewPagesStack(state) {
    state.previewPagesStack = [];
  },
  showEndQuizPage(state, obj) {
    state.selectedPage = obj;
  },
  setIsloadQuizCustomizationTriggered(state, val) {
    state.isloadQuizCustomizationTriggered = val;
  },
  setIsUpdateQuizCustomizationTriggered(state, val) {
    state.isupdateQuizCustomizationTriggered = val;
  },
  setEditorPageView(state, val) {
    state.editorPageView = val;
  },

  RESET_STATE(state) {
    // const  x = getDefaultState()

    // Object.keys(x).forEach(key => {
    //     state[key] = x[key]

    // })

    state.QuizStarted = false;
  },

  setSavedLeadID(state, lead) {
    state.savedLeadId = lead;
  },

  updateSavedLeads(state, leadObj) {
    if (leadObj) {
      Object.keys(leadObj).forEach((key) => {
        if (key in state.savedLeadId && key !== "freeTextNote") {
          if (key == "address") {
            state.savedLeadId[key] = {
              ...state.savedLeadId[key],
              ...leadObj[key],
            };
          } else if (key == "custom_inputs") {
            state.savedLeadId[key] = {
              ...state.savedLeadId[key],
              ...leadObj[key],
            };
          } else {
            state.savedLeadId[key] = leadObj[key];
          }
        }
        if (key in state.savedLeadId && key == "freeTextNote") {
          // state.savedLeadId[key] =    leadObj[key]
          state.savedLeadId[key] = [...state.savedLeadId[key], ...leadObj[key]];
        }
      });
    }
  },
  setTranslation(state, translation) {
    state.translation = translation;
  },
  setQuizStarted(state) {
    state.QuizStarted = !state.QuizStarted;
  },
  resetQuizStarted(state) {
    state.QuizStarted = false;
  },
  setQuizId(state, QuizID) {
    if (QuizID) {
      state.QuizId = QuizID;
    }
  },
  setQuizKey(state, QuizKeyValue) {
    if (QuizKeyValue) {
      state.QuizKey = QuizKeyValue;
    }
  },
  ResetQuizId(state) {
    state.QuizId = "";
  },
  ResetQuizKey(state) {
    state.QuizKey = "";
  },

  setQuizTitle(state, title) {
    state.CurrentQuizTitle = title;
  },
  setNavbarData(state, payload) {
    state.accountSetting = payload;
    state.shopOrigin = payload.name;
    state.apiKey = payload.apiKey;
    state.isShopifyUser = payload.isShopifyUser;

    state.shopifySelectedProducts = payload.shopifySelectedProducts;
  },
  setUserLimitation(state, payload) {
    state.limitations = payload;
  },
  setUserAllAccountData(state, payload) {
    state.UserAllAccountData = payload;
  },
  setUserPlanData(state, payload) {
    state.loggedInUserPlanDetails = payload;
  },

  setUserShareData(state, payload) {
    if (payload) {
      state.UserShareData = payload;
    }
  },

  nextPageAnimation(state) {
    return state.accountSetting;
  },
  backPageAnimation(state) {
    return state.accountSetting;
  },
  setAnimationValue(state, obj) {
    state.EditorSettings.animation = obj;
  },
  setLoadQuizAppsIntegrated(state, arr) {
    state.LoadQuizAppsIntegrated = arr;
  },

  setUpgradePlansModal(state, obj) {
    state.UpgradePlanModal = obj;
  },

  resetUpgradePlansModal(state) {
    state.UpgradePlanModal = {
      isOpen: false,
      title: "",
      subText: "",
    };
  },

  setPageZoom(state, payload) {
    state.pageZoom = payload;
  },
  setPopulateTrackCounter(state, payload) {
    if (Object.keys(state.trackIkeaClientCounter).length == 0) {
      state.trackIkeaClientCounter = payload;
    }
  },
  resetPopulateTrackCounter(state) {
    state.trackIkeaClientCounter = {};
    state.currentActiveCounterKey = "";
  },
  setIkeaClientCounter(state, key) {
    state.trackIkeaClientCounter[key].counter += 1;

    state.currentActiveCounterKey = key;
  },
  filterSelectedQuestionAnswer(state, payload) {
    state.selectedOptions.questionAnswer = payload;
  },
  toggleCustomNav(state) {
    state.isCustomNavOpen = !state.isCustomNavOpen;
  },
  toggleFlowComponentDragging(state) {
    state.isFlowComponentsDragging = !state.isFlowComponentsDragging;
  },
  updateCurrEmailDesignEdit(state, payload) {
    if (payload) {
      state.currentEmailDesignEditData = payload;
    }
  },
  resetCurrEmailDesignEdit(state) {
    state.currentEmailDesignEditData = {};
  },
  updateUserCustomLeadIputs(state, payload) {
    state.userCustomLeadsInput = payload;
  },
  updateQuizLoader(state, payload) {
    state.quizLoader = payload;
  },
  assignQuizAnaylticsId(state, payload) {
    state.savedLeadId["quiz_analytic_id"] = payload;
  },
  setCurrSidebarView(state,payload){
    state.currSidebarView = payload
  },
  toggleIsAccountDataLoading(state,payload){
    state.isLoadAccountDataLoading = payload
  },
  setKozimaIntervalValue(state,payload){
    state.kozimaIntervalValue=payload
  },
  setKozimaTimerSec(state,payload)
  {
    state.kozimaTimerSec=payload
  },
  setKozimCurrectSectionIndex(state,payload){state.kozimCurrectSectionIndex=payload  
},
resetKozimaDetail(state){
  state.kozimaTimerSec=50;
 console.log(state.previewSettings)
  console.log(state.previewSettings.env_contants?.KOZIMA_GAME)
  state.kozmikQuizDetail=state.previewSettings.env_contants?.KOZIMA_GAME?JSON.parse(state.previewSettings.env_contants.KOZIMA_GAME):[];
    state.kozimCurrectSectionIndex=0;
}
}
