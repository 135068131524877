<template>
  <div
    id="app"
    :style="{
      background:
        $router.name != 'QuizFinal' ? '#F5F8FA !important;' : 'transparent',
    }"
  >
    <router-view />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      GoogleTagID: "GTM-MTBRLNZZ",
    };
  },
  computed: {
    ...mapGetters(["getUserAllAccountData"]),
    userAccountData() {
      return this.getUserAllAccountData.account
        ? this.getUserAllAccountData.account
        : null;
    },
    isLoginOrSignupPage() {
      return this.$route.name == "login" || this.$route.name == "sign-up";
    },
  },
  methods: {
    isCrispScriptPresent() {
      return !!document.getElementById("crisp-chat-script");
    },
    addCrispScript() {
      if (!this.isCrispScriptPresent()) {
        const script = document.createElement("script");
        script.id = "crisp-chat-script";
        script.innerHTML = `window.$crisp=[];window.CRISP_WEBSITE_ID="1f841a7a-7115-482c-bb41-eeea81f29ba3";(function(){ d=document;s=d.createElement("script"); s.src="https://client.crisp.chat/l.js"; s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`;

        const stylesheetLink = document.createElement("link");
        stylesheetLink.rel = "stylesheet";
        stylesheetLink.href = `https://client.crisp.chat/static/stylesheets/client_default.css`;

        // Append the  tag to the head of the document
        document.head.appendChild(script);
        document.head.appendChild(stylesheetLink);
      }
    },
    removeCrispScript() {
      const script = document.getElementById("crisp-chat-script");
      if (script) {
        script.parentNode.removeChild(script);
      }

      const elementsToRemove = [
        ...document.querySelectorAll(
          'script[src="https://client.crisp.chat/l.js"], ' +
            'script[src^="https://client.crisp.chat/static/javascripts/client.js"]'
        ),
        ...document.querySelectorAll(
          'link[href="https://client.relay.crisp.chat"], ' +
            'link[href="https://client.crisp.chat"], ' +
            'link[href^="https://client.crisp.chat/static/stylesheets/client_default.css"]'
        ),
      ];

      elementsToRemove.forEach((el) => el.remove());
      const crispClientDiv = document.querySelector(".crisp-client");
      if (crispClientDiv) {
        crispClientDiv.remove();
      }
    },
    isGtmScriptPresent() {
      return !!document.getElementById("gtm-script");
    },
    addGtmScript() {
      if (!this.isGtmScriptPresent()) {
        const script = document.createElement("script");
        script.id = "gtm-script";
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${this.GoogleTagID}');`;

        document.head.appendChild(script);
      }
    },
    removeGtmScript() {
      const script = document.getElementById("gtm-script");
      if (script) {
        script.parentNode.removeChild(script);
      }
    },

    
  },

  created() {
   
  },
  watch: {
    $route(to) {
      if (
        to.path === "/login" ||
        to.path === "/sign-up" ||
        to.path === "/email-verify"
      ) {
        this.addGtmScript();
      } else {
        this.removeGtmScript();
      }

      if (typeof to.meta.loadCrisp != "undefined" && !to.meta.loadCrisp) {
        this.removeCrispScript();
      } else {
        this.addCrispScript();

        if (to.name === "SomeOtherPageName") {
          let HotjarExternalScript = document.createElement("script");
          HotjarExternalScript.innerHTML = `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:3098546,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
        })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

          document.head.appendChild(HotjarExternalScript);
        }
      }
    },
  },
};
</script>
<style></style>
