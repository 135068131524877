export default {
  getSelectedPage: (state) => state.selectedPage,
  getProgressBar: (state) => state.progressBar,
  getPreviewProgressBar: (state) => state.previewProgressBar,
  getTotalPages: (state) => state.totalPages,
  getTotalPreviewPages: (state) => state.previewTotalPages,
  getPreviewSettings: (state) => state.previewSettings,
  getEnvConst: (state) => state.previewSettings.env_contants,
  getEditorSettings: (state) => state.EditorSettings,
  getCurrencies: (state) => state.Currencies,
  getSelectedOptions: (state) => state.selectedOptions,
  getFurnitureClientCounterData: (state) => () => {
    let CounterData = {};
    let index = state.selectedOptions.questionAnswer.findIndex(
      (x) => x.questionId == 22259
    );
    if (index > -1) {
      let options = [
        ...state.selectedOptions.questionAnswer[index].selectedOption,
      ];
      CounterData = options.reduce((acc, option) => {
        const key = option.value.toLowerCase().replace(/\s/g, "_");
        acc[key] = {
          counter: option.counterValue,
          select_status: true,
          optionId: option.id,
          products: [],
        };
        return acc;
      }, {});
    }
    return CounterData;
  },
  getIsLoadingQuestion: (state) => state.isLoadingQuestion,
  getLeadCounterObj: (state) => state.currLeadPageCounter,
  getTrackIkeaClientCounter: (state) => state.trackIkeaClientCounter,
  getCurrentActiveCounterKey: (state) => state.currentActiveCounterKey,
  getUploadedImage: (state) => state.uploadedImage,
  getIsAnyPageEdited: (state) => state.isAnyPageEdited,
  getSelectedTemplateDesign: (state) => state.selectedTemplateDesign,
  getCurrPageOrder: (state) => state.currPageOrder,
  getIsloadQuizCustomizationTriggered: (state) =>
    state.isloadQuizCustomizationTriggered,
  getIsUpdateQuizCustomizationTriggered: (state) =>
    state.isupdateQuizCustomizationTriggered,
  getEditorPageView: (state) => state.editorPageView,
  getSavedLeadID: (state) => state.savedLeadId,
  getSelectedLanguage: (state) => state.translation.selectedLanguage,
  getAllLanguages: (state) => state.translation?.languages,
  translateText: (state) => (text) => {
    let ConvertedText = "";
    let Locale = null;
    try {
      Locale = state.translation
    } catch (e) {
      Locale = state.translation.selectedLanguage.messages;
    }
    if (Locale && Locale.length) {
      Locale.forEach((x) => {
        if (x.text == text) {
          ConvertedText = x.convertedText;
        }
      });
    }

    return ConvertedText !== "" ? ConvertedText : text;
  },
  isLastLeadPage: (state) => () => {
    let { single_step_status } = state.previewSettings;
    if (single_step_status) {
      let currentCounterValue = state.currLeadPageCounter.counter;
      let totalLeadCount = state.pageCounter.total_lead_page;
      return currentCounterValue == totalLeadCount;
    } else {
      let allPages = [...state.previewTotalPages];
      let currentPage = state.selectedPage;

      const leadObj = allPages
        .reverse()
        .find((item) => item.value === "leadPage");
      return (
        leadObj &&
        leadObj.id === currentPage.id &&
        leadObj.value === currentPage.value
      );
    }
  },

  GetTokenFromMetaTag: () => {
    const token = "Quizell";
    return token;
  },
  getQuizStarted: (state) => state.QuizStarted,
  // getQuizKey: state => state.EditorSettings.quizKey,
  getQuizKey: (state) => state.QuizKey,
  getQuizID: (state) => state.QuizId,
  getQuizTitle: (state) => state.CurrentQuizTitle,
  getNavbarData: (state) => state.accountSetting,
  getPlanData: (state) => state.UserAllAccountData.plan,
  getIsShopifyUser: (state) => state.isShopifyUser,
  getUserShareData: (state) => state.UserShareData,
  getAnimationSetting: (state) => state.previewSettings?.animation,
  getLoadQuizAppsIntegrated: (state) => state.LoadQuizAppsIntegrated,
  getHidePrice: (state) => state.previewSettings.hidePrice,
  getUserAllAccountData: (state) => state.UserAllAccountData,
  getPageZoom: (state) => state.pageZoom,
  getIsCustomNavOpen: (state) => state.isCustomNavOpen,
  getAlertNotification: (state) => state.UserAllAccountData.alert_notification,
  getCustomNavFlexGrowValue(state) {
    return state.isCustomNavOpen ? 0.2 : 0.05;
  },
  getProductScoreMapStatus: (state) =>
    state.EditorSettings.product_score_map_status,
  getUserLimitations: (state) => state.limitations,
  getFlowCompDragging: (state) => state.isFlowComponentsDragging,
  getCurrEmailDesignEditData: (state) => state.currentEmailDesignEditData,
  getUserCustomLeadInputFields: (state) => state.userCustomLeadsInput,
  getAllQuestions: (state) => {
    let allPages = [...state.totalPages];

    return allPages
      .filter(
        (obj) => obj.value == "singleAnswer" || obj.value == "multipleAnswer"
      )
      .map((ques, index) => {
        let { id, name, order, value } = ques;
        let personalization = `QA-${index + 1}-${id}`;

        return { id, name, order, value, personalization: personalization };
      });
  },
  getAllPreviewQuestions: (state) => {
    let allPages = [...state.previewTotalPages];

    return allPages
      .filter(
        (obj) => obj.value == "singleAnswer" || obj.value == "multipleAnswer"
      )
      .map((ques, index) => {
        let { id, name, order, value } = ques;
        let personalization = `QA-${index + 1}-${id}`;

        return { id, name, order, value, personalization: personalization };
      });
  },
  getQuizLoader: (state) => state.quizLoader,
  getIsInvitedUser: (state) => state.isInvitedUser,
  getSliderValues: (state) => state.selectedRangeSliders,
  getCurrSidebarValue: (state) => state.currSidebarView,
  getIsAccountDataLoading: (state) => state.isLoadAccountDataLoading,
  getkozmikQuizDetail: (state) => {
    state.kozmikQuizDetail=state.previewSettings.env_contants?.KOZIMA_GAME?JSON.parse(state.previewSettings.env_contants.KOZIMA_GAME):[];
   return state.kozmikQuizDetail},
  getKozmikCurrentSection: ({ kozmikQuizDetail, kozimCurrectSectionIndex }) =>
    kozmikQuizDetail[kozimCurrectSectionIndex],
  getKozmikCurrentSectionPages: ({
    kozmikQuizDetail,
    kozimCurrectSectionIndex,
    previewTotalPages,
  }) => {
    const currentSection = kozmikQuizDetail[kozimCurrectSectionIndex];

    const pages = previewTotalPages.filter((page) =>
      currentSection.questions.includes(page.id)
    );

    return pages;
  },
  getkozimaIntervalValue: ({ kozimaIntervalValue }) => kozimaIntervalValue,
  getKozimaTimerSec: ({ kozimaTimerSec }) => kozimaTimerSec,
  getKozimCurrectSectionIndex: ({ kozimCurrectSectionIndex }) =>
    kozimCurrectSectionIndex,
};
